var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shell-box"},[_c('el-breadcrumb',{staticClass:"breadcrumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/myHome' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',{attrs:{"to":{
        path: `/routerPolicyManagement/${_vm.$route.meta.policyRoute}/EnquiryManagement`,
      }}},[_vm._v("询价单列表")]),_c('el-breadcrumb-item',[_vm._v("询价单详情")])],1),_c('div',{staticClass:"form"},[_c('enquiryDetails',{attrs:{"detailData":_vm.enquiryDetail,"updateDetail":_vm.updateRecordDetail,"auditProcessList":_vm.auditProcessList,"permissions":_vm.policyDetailPermissions,"confirData":_vm.confirData}})],1),_c('div',{staticClass:"operating-box"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.handleBack}},[_vm._v("返回")]),(
        _vm.enquiryDetail.approvalStatus != 2 &&
        _vm.hasPerms(`M26-edit_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.handleEnquiryEdit}},[_vm._v("编辑")]):_vm._e(),(_vm.enquiryDetail.approvalStatus == 2)?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.handleRevocation}},[_vm._v("撤回")]):_vm._e(),(
        (_vm.enquiryDetail.approvalStatus == 5 ||
          _vm.enquiryDetail.approvalStatus == 3) &&
        _vm.hasPerms(`M26-generateBJD_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"warning"},on:{"click":_vm.handleChangeToInsuranceDoc}},[_vm._v("生成报价单")]):_vm._e(),(
        (_vm.enquiryDetail.approvalStatus == 5 ||
          _vm.enquiryDetail.approvalStatus == 3) &&
        _vm.hasPerms(`M26-generateTBD_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"warning"},on:{"click":_vm.handleChangeToInsurance}},[_vm._v("生成投保单")]):_vm._e(),(
        (_vm.enquiryDetail.approvalStatus == 5 ||
          _vm.enquiryDetail.approvalStatus == 3) &&
        _vm.hasPerms(`M26-turn_${_vm.$route.meta.policyType}`)
      )?_c('el-button',{attrs:{"size":"mini","type":"warning","plain":""},on:{"click":function($event){_vm.isTurnPolicy = true}}},[_vm._v("转保单")]):_vm._e(),(_vm.hasPerms(`M26-accessory_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.downloadAllAttachment}},[_vm._v("下载全部附件")]):_vm._e()],1),_c('DragMultipleUpload',{attrs:{"show":_vm.showUpload,"dataEcho":_vm.dataEcho,"fileSzie":10,"canEdit":false},on:{"update:show":function($event){_vm.showUpload=$event}}}),_c('turn-policy',{attrs:{"id":_vm.enquiryDetail.id,"bindUserId":_vm.enquiryDetail.bindUserId},model:{value:(_vm.isTurnPolicy),callback:function ($$v) {_vm.isTurnPolicy=$$v},expression:"isTurnPolicy"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }