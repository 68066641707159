<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/${$route.meta.policyRoute}/EnquiryManagement`,
        }"
        >询价单列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>询价单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <enquiryDetails
        :detailData="enquiryDetail"
        :updateDetail="updateRecordDetail"
        :auditProcessList="auditProcessList"
        :permissions="policyDetailPermissions"
        :confirData="confirData"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="handleEnquiryEdit"
        v-if="
          enquiryDetail.approvalStatus != 2 &&
          hasPerms(`M26-edit_${$route.meta.policyType}`)
        "
        >编辑</el-button
      >
      <el-button
        size="mini"
        type="primary"
        plain
        @click="handleRevocation"
        v-if="enquiryDetail.approvalStatus == 2"
        >撤回</el-button
      >
      <el-button
        size="mini"
        type="warning"
        @click="handleChangeToInsuranceDoc"
        v-if="
          (enquiryDetail.approvalStatus == 5 ||
            enquiryDetail.approvalStatus == 3) &&
          hasPerms(`M26-generateBJD_${$route.meta.policyType}`)
        "
        >生成报价单</el-button
      >
      <el-button
        size="mini"
        type="warning"
        @click="handleChangeToInsurance"
        v-if="
          (enquiryDetail.approvalStatus == 5 ||
            enquiryDetail.approvalStatus == 3) &&
          hasPerms(`M26-generateTBD_${$route.meta.policyType}`)
        "
        >生成投保单</el-button
      >
      <el-button
        size="mini"
        type="warning"
        plain
        @click="isTurnPolicy = true"
        v-if="
          (enquiryDetail.approvalStatus == 5 ||
            enquiryDetail.approvalStatus == 3) &&
          hasPerms(`M26-turn_${$route.meta.policyType}`)
        "
        >转保单</el-button
      >
      <!-- enquiryDetail.surePress && -->
      <!-- <el-button
        size="mini"
        type="primary"
        @click="showUpload = true"
        v-if="hasPerms(`M26-accessory_${$route.meta.policyType}`)"
        >查看附件</el-button
      > -->
      <el-button
        size="mini"
        type="primary"
        @click="downloadAllAttachment"
        v-if="hasPerms(`M26-accessory_${$route.meta.policyType}`)"
        >下载全部附件</el-button
      >
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :dataEcho="dataEcho"
      :fileSzie="10"
      :canEdit="false"
    ></DragMultipleUpload>
    <turn-policy
      v-model="isTurnPolicy"
      :id="enquiryDetail.id"
      :bindUserId="enquiryDetail.bindUserId"
    />
  </div>
</template>

<script>
import enquiryDetails from "@/views/EnquiryManagement/components/details.vue";
import {
  insNewEnquiryDetail,
  getUpdateRecord,
  changeToInsurance,
  generateQuotationSheet,
  policyProcessGetNodeRecord,
  policyProcessRevoke,
  insConfirmationDetail,
} from "@/api/policy";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import TurnPolicy from "@/views/policyManagement/components/TurnPolicy.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { downloadZIP } from "@/utils/index";
export default {
  components: { enquiryDetails, DragMultipleUpload, TurnPolicy },
  data() {
    return {
      enquiryDetail: {},
      confirData: {},
      updateRecordDetail: [],
      showUpload: false,
      dataEcho: {},
      hasPerms: hasPerms,
      policyDetailPermissions: {
        agentId: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "agentId"
        ),
        agentName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "agentName"
        ),
        salesmanName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "salesmanName"
        ),
        enquiryNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "enquiryNo"
        ),
        // "desc": "代理人",
        applicantMode: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantMode"
        ),
        // "desc": "投保方式",
        lastPolicyNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "lastPolicyNo"
        ),
        // "desc": "上一年保单号",
        endDate: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "endDate"
        ),
        // "desc": "到期时间",
        city: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "city"
        ),
        // "desc": "所属地区",
        name: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "name"
        ),
        // "desc": "保单号",
        province: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "province"
        ),
        // "desc": "所属省份",
        applicantType: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantType"
        ),
        // "desc": "投保类型",
        acceptCompanyList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "acceptCompanyList"
        ),
        // "desc": "承保公司",
        extensionClause: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "extensionClause"
        ),
        // "desc": "免费扩展条款",
        specialAgreement: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "specialAgreement"
        ),
        // "desc": "特别约定",
        applicantStore: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantStore"
        ),
        // "desc": "投保门店",
        absoluteDeductible3: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible3"
        ),
        // "desc": "未扩展不计免赔",
        absoluteDeductible2: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible2"
        ),
        // "desc": "扩展不计免赔2",
        salesmanId: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "salesmanId"
        ),
        // "desc": "业务员",
        agentNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "agentNo"
        ),
        // "desc": "代理人编码",
        beneficiaryList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "beneficiaryList"
        ),
        // "desc": "受益人",
        policyHolderList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyHolderList"
        ),
        // "desc": "投保人",
        totalPremium: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "totalPremium"
        ),
        // "desc": "总保费",
        ep: dataPermissions(`M26_${this.$route.meta.policyType}-View`, "ep"),
        // "desc": "电子保单",
        projectRemark: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "projectRemark"
        ),
        // "desc": "项目备注",
        policyPlanList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyPlanList"
        ),
        // "desc": "保障计划",
        policySubjectList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policySubjectList"
        ),
        // "desc": "标的信息",
        absoluteDeductible: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible"
        ),
        // "desc": "扩展不计免赔1",
        policyInsuredList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyInsuredList"
        ),
        // "desc": "被保险人",
        projectName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "projectName"
        ),
        // "desc": "项目名称",
        startDate: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "startDate"
        ),
        // "desc": "起保时间",
        enquiryNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "enquiryNo"
        ),
        // "desc": "询价单号",
        recorder: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "recorder"
        ),
        // "desc": "录单员",

        specialVersion: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "specialVersion"
        ),
        // "desc": "特别说明",
        bindUser: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "bindUser"
        ),
        // "desc": "绑定客户",
        salesmanDeptName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "salesmanDeptName"
        ),
        // "desc": "所属部门",
        sourceChannel: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "sourceChannel"
        ),
        // "desc": "业务渠道",
        upstreamAgent: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "upstreamAgent"
        ),
        // "desc": "业务代理人",
        insuredCategory: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "insuredCategory"
        ),
        // "desc": "险种类型",
        createTime: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "createTime"
        ),
        // "desc": "创建时间",
      },
      isTurnPolicy: false,
      auditProcessList: [],
    };
  },
  created() {
    this.getDetail();
  },
  activated() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      insNewEnquiryDetail(this.$route.params.id)
        .then((res) => {
          this.enquiryDetail = res.data;
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1] || 0),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : "";
          this.dataEcho = dataBackShow;
          policyProcessGetNodeRecord({
            bussId: res.data.id,
            processType: 1,
          }).then((res2) => {
            this.auditProcessList = res2.data;
          });
          if (res.data.surePress && res.data.verifyLine) {
            insConfirmationDetail({ enquiryId: this.$route.params.id }).then(
              (res) => {
                this.confirData = res.data;
              }
            );
          }
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
      getUpdateRecord({
        policyId: this.$route.params.id,
      })
        .then((res) => {
          this.updateRecordDetail = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleEnquiryEdit(e) {
      this.$router.push({
        path:
          `/routerPolicyManagement/${this.$route.meta.policyRoute}/enquiryEdit/` +
          this.enquiryDetail.id +
          "/" +
          this.enquiryDetail.enquiryType,
      });
    },
    handleChangeToInsurance() {
      changeToInsurance({ id: this.enquiryDetail.id });
    },
    handleChangeToInsuranceDoc() {
      generateQuotationSheet({ id: this.enquiryDetail.id });
    },
    handleRevocation(item) {
      this.$confirm("是否确认撤回？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          policyProcessRevoke({
            processType: 1,
            bussId: this.enquiryDetail.id,
            processId: this.enquiryDetail.processId,
          }).then((res) => {
            this.getDetail();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    downloadAllAttachment() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      this.dataEcho.forEach((item) => {
        item.urls.forEach((urlitem) => {
          if (urlitem.url) {
            arr.push({
              name: `${urlitem.name}.${urlitem.suffix}`,
              url: urlitem.url,
            });
          }
        });
      });
      if (arr.length) {
        downloadZIP(arr, "附件")
          .then((res) => {
            loading.close();
          })
          .catch((err) => {
            loading.close();
          });
      } else {
        this.$message.warning("暂无可下载的附件！");
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;

  .form {
    flex: 1;
    overflow: hidden;
  }

  .breadcrumb {
    padding: 10px 0;

    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }

  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
