<template>
  <div class="el-collapse-box">
    <AssociationScroll>
      <AssociationScrollView title="基本信息" name="0">
        <div style="padding: 18px 12px 12px">
          <el-descriptions
            class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style"
          >
            <el-descriptions-item
              label="投保类型"
              v-if="permissions.applicantType && $route.meta.policyType == 1"
            >
              <template slot="label">
                <span
                  v-if="$route.meta.policyType == 1"
                  style="color: rgba(201, 66, 66, 1)"
                  >*</span
                >
                投保类型
              </template>
              {{ detailData.applicantTypeName }}
            </el-descriptions-item>
            <el-descriptions-item label="起保时间" v-if="permissions.startDate">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                起保时间
              </template>
              {{ detailData.startDate }}
            </el-descriptions-item>
            <el-descriptions-item v-if="permissions.endDate">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                保险到期
              </template>
              {{ detailData.endDate }}
            </el-descriptions-item>
            <el-descriptions-item v-if="permissions.salesmanName">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                业务员
              </template>
              {{ detailData.salesmanName }}
            </el-descriptions-item>
            <el-descriptions-item v-if="permissions.recorder">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                录单员
              </template>
              {{ detailData.recorderName }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="所属省份"
              v-if="permissions.province">{{ detailData.province }}</el-descriptions-item>

            <el-descriptions-item label="所属地区"
              v-if="permissions.city">{{
              detailData.city
            }}</el-descriptions-item> -->
            <el-descriptions-item
              label="所属部门"
              v-if="permissions.salesmanDeptName"
              >{{ detailData.salesmanDeptName }}</el-descriptions-item
            >
            <el-descriptions-item
              label="总保费"
              v-if="$route.meta.policyType != 1 && permissions.totalPremium"
              >{{
                detailData.totalPremium
                  ? Number(detailData.totalPremium).toFixed(2)
                  : "0.00"
              }}</el-descriptions-item
            >
            <el-descriptions-item
              label="业务渠道"
              v-if="permissions.sourceChannel"
            >
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                业务渠道
              </template>
              {{ detailData.sourceChannel }}
            </el-descriptions-item>
            <el-descriptions-item label="代理人" v-if="permissions.agentName">
              <template slot="label">
                <!-- <span style="color:rgba(201, 66, 66, 1)">*</span> -->
                代理人
              </template>
              {{ detailData.agentName }}
            </el-descriptions-item>
            <el-descriptions-item label="代理人编码" v-if="permissions.agentNo">
              <template slot="label">
                <span style="color: rgba(201, 66, 66, 1)">*</span>
                代理人编码
              </template>
              {{ detailData.agentNo }}
            </el-descriptions-item>
            <el-descriptions-item
              label="投保门店"
              v-if="permissions.applicantStore && $route.meta.policyType == 1"
              >{{ detailData.applicantStoreName }}</el-descriptions-item
            >
            <el-descriptions-item
              label="上一年保单号"
              v-if="permissions.lastPolicyNo"
            >
              {{ detailData.lastPolicyNo }}
              <!-- <el-button
                type="text"
                style="color: rgba(0, 128, 255, 1);padding: 0;"
              >{{ detailData.lastPolicyNo }}</el-button>-->
              <img
                src="@/assets/images/policy/copyText.png"
                alt
                v-if="detailData.lastPolicyNo"
                @click="handleCopy(detailData.lastPolicyNo)"
                style="
                  width: 16px;
                  height: 16px;
                  margin-top: 2px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                srcset
              />
            </el-descriptions-item>
            <el-descriptions-item label="询价单号" v-if="permissions.enquiryNo">
              {{ detailData.enquiryNo }}
              <!-- <el-button
                type="text"
                style="color: rgba(0, 128, 255, 1);padding: 0;"
              >{{ detailData.enquiryNo }}</el-button>-->
              <img
                src="@/assets/images/policy/copyText.png"
                alt
                v-if="detailData.enquiryNo"
                @click="handleCopy(detailData.enquiryNo)"
                style="
                  width: 16px;
                  height: 16px;
                  margin-top: 2px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                srcset
              />
            </el-descriptions-item>
            <!-- <el-descriptions-item
              label="投保类型"
              v-if="permissions.applicantTypeName"
            >{{ detailData.applicantTypeName }}</el-descriptions-item>-->
            <el-descriptions-item
              label="投保方式"
              v-if="permissions.applicantMode && $route.meta.policyType === 1"
              >{{ detailData.applicantModeName }}</el-descriptions-item
            >
            <!-- && $route.meta.policyType != 2  车险类型添加项目名称 -->
            <el-descriptions-item
              label="项目名称"
              v-if="permissions.projectName"
              ><template slot="label">
                <span
                  v-if="$route.meta.policyType == 1"
                  style="color: rgba(201, 66, 66, 1)"
                  >*</span
                >
                项目名称 </template
              >{{ detailData.projectName }}</el-descriptions-item
            >
            <el-descriptions-item
              label="创建时间"
              v-if="permissions.createTime"
              >{{ detailData.createTime }}</el-descriptions-item
            >
            <el-descriptions-item label="绑定客户" v-if="permissions.bindUser"
              >{{ detailData.bindUserName }}-{{
                detailData.bindUserPhone
              }}</el-descriptions-item
            >
            <el-descriptions-item
              label="业务代理人"
              v-if="permissions.upstreamAgent && false"
              >{{ detailData.upstreamAgentName }}</el-descriptions-item
            >
            <el-descriptions-item
              label="险种类型"
              v-if="permissions.insuredCategory && $route.meta.policyType !== 1"
              >{{ detailData.insuredCategoryName }}</el-descriptions-item
            >
          </el-descriptions>
          <div class="policy-label-style" v-if="permissions.projectRemark">
            项目备注
          </div>
          <el-input
            type="textarea"
            v-if="permissions.projectRemark"
            :rows="6"
            v-model="detailData.projectRemark"
            disabled
          ></el-input>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="投保人"
        name="2"
        v-if="permissions.policyHolderList"
      >
        <div style="padding: 18px 12px 12px">
          <div
            v-for="(item, index) in detailData.policyHolderList"
            :key="index"
          >
            <div class="personnel_repetition" v-if="item.check">
              <i
                class="el-icon-warning"
                style="
                  color: rgba(252, 184, 24, 1);
                  margin-right: 8px;
                  font-size: 24px;
                "
              ></i>
              <span>重复提示：此投保人（{{ item.cardNo }}）已重复存在！</span>
              <span
                class="personnel_repetition_details"
                @click="lookRepetition(item, 2)"
                >查看详情</span
              >
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
              "
            >
              <el-radio-group disabled v-model="item.playersType">
                <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
                <el-radio :label="2">个人</el-radio>
              </el-radio-group>
            </div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="投保人">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  投保人
                </template>
                {{ item.playersName }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件类型
                </template>
                {{
                  item.playersType == 1 ? "统一社会信用代码" : item.cardTypeName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件号码
                </template>
                {{ item.cardNo }}
              </el-descriptions-item>
              <el-descriptions-item label="所在区域">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  所在区域
                </template>
                {{ item.province }}{{ item.city }}
              </el-descriptions-item>
              <template v-if="$route.meta.policyType === 1">
                <el-descriptions-item
                  label="出生日期"
                  v-if="item.playersType == 2"
                >
                  <template slot="label">
                    <!-- <span style="color: rgba(201, 66, 66, 1)">*</span> -->
                    出生日期
                  </template>
                  {{ item.birthday }}
                </el-descriptions-item>
                <el-descriptions-item label="性别" v-if="item.playersType == 2">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    性别
                  </template>
                  {{ item.sex ? "男" : "女" }}
                </el-descriptions-item>
                <!-- <el-descriptions-item label="详细地址">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    详细地址
                  </template>
                  {{ item.detailAddress }}
                </el-descriptions-item> -->
                <el-descriptions-item label="证件有效期">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    证件有效期
                  </template>
                  {{ item.cardStart }} -
                  {{ !item.cardEnd && item.cardStart ? "长期" : item.cardEnd }}
                </el-descriptions-item>
                <el-descriptions-item label="联系人名称">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系人名称
                  </template>
                  {{ item.contactName }}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系电话
                  </template>
                  {{ item.contactPhone }}
                </el-descriptions-item>
                <el-descriptions-item
                  label="联系邮箱"
                  v-if="!$route.meta.policyType == 1"
                  >{{ item.contactEmail }}</el-descriptions-item
                >
              </template>
              <!-- $route.meta.policyType == 3 -->
              <template v-else>
                <el-descriptions-item label="联系人名称">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系人名称
                  </template>
                  {{ item.contactName }}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系电话
                  </template>
                  {{ item.contactPhone }}
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="被保险人"
        name="1"
        v-if="permissions.policyInsuredList"
      >
        <div style="padding: 18px 12px 12px">
          <div
            v-for="(item, index) in detailData.policyInsuredList"
            :key="index"
          >
            <div class="personnel_repetition" v-if="item.check">
              <i
                class="el-icon-warning"
                style="
                  color: rgba(252, 184, 24, 1);
                  margin-right: 8px;
                  font-size: 24px;
                "
              ></i>
              <span>重复提示：此被保险人（{{ item.cardNo }}）已重复存在！</span>
              <span
                class="personnel_repetition_details"
                @click="lookRepetition(item, 1)"
                >查看详情</span
              >
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
              "
            >
              <el-radio-group disabled v-model="item.playersType">
                <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
                <el-radio :label="2">个人</el-radio>
              </el-radio-group>
            </div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="被保险人">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  被保险人
                </template>
                {{ item.playersName }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件类型
                </template>
                {{
                  item.playersType == 1 ? "统一社会信用代码" : item.cardTypeName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  证件号码
                </template>
                {{ item.cardNo }}
              </el-descriptions-item>
              <el-descriptions-item label="所在区域">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  所在区域
                </template>
                {{ item.province }}{{ item.city }}
              </el-descriptions-item>
              <template v-if="$route.meta.policyType === 1">
                <el-descriptions-item
                  label="出生日期"
                  v-if="item.playersType == 2"
                >
                  <template slot="label">
                    <!-- <span style="color: rgba(201, 66, 66, 1)">*</span> -->
                    出生日期
                  </template>
                  {{ item.birthday }}
                </el-descriptions-item>
                <el-descriptions-item label="性别" v-if="item.playersType == 2">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    性别
                  </template>
                  {{ item.sex ? "男" : "女" }}
                </el-descriptions-item>
                <!-- <el-descriptions-item label="详细地址">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    详细地址
                  </template>
                  {{ item.detailAddress }}
                </el-descriptions-item> -->
                <el-descriptions-item label="证件有效期">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    证件有效期
                  </template>
                  {{ item.cardStart }} -
                  {{ !item.cardEnd && item.cardStart ? "长期" : item.cardEnd }}
                </el-descriptions-item>
                <el-descriptions-item label="联系人名称">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系人名称
                  </template>
                  {{ item.contactName }}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系电话
                  </template>
                  {{ item.contactPhone }}
                </el-descriptions-item>
                <el-descriptions-item
                  label="联系邮箱"
                  v-if="!$route.meta.policyType == 1"
                  >{{ item.contactEmail }}</el-descriptions-item
                >
              </template>
              <!-- if="$route.meta.policyType == 3" -->
              <template v-else>
                <el-descriptions-item label="联系人名称">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系人名称
                  </template>
                  {{ item.contactName }}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    联系电话
                  </template>
                  {{ item.contactPhone }}
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="受益人"
        name="3"
        v-if="permissions.beneficiaryList && $route.meta.policyType !== 3"
      >
        <div style="padding: 18px 12px 12px">
          <div v-for="(item, index) in detailData.beneficiaryList" :key="index">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;
              "
            >
              <el-radio-group disabled v-model="item.playersType">
                <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
                <el-radio :label="2">个人</el-radio>
              </el-radio-group>
            </div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="受益人">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  受益人
                </template>
                {{ item.playersName }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  证件类型
                </template>
                {{
                  item.playersType == 1 ? "统一社会信用代码" : item.cardTypeName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="证件类型">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  证件号码
                </template>
                {{ item.cardNo }}
              </el-descriptions-item>
              <el-descriptions-item
                label="出生日期"
                v-if="item.playersType == 2"
              >
                <template slot="label">
                  <!-- <span style="color: rgba(201, 66, 66, 1)">*</span> -->
                  出生日期
                </template>
                {{ item.birthday }}
              </el-descriptions-item>
              <el-descriptions-item label="性别" v-if="item.playersType == 2">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  性别
                </template>
                {{ item.sex ? "男" : "女" }}
              </el-descriptions-item>
              <el-descriptions-item label="所在区域">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  所在区域
                </template>
                {{ item.province }}{{ item.city }}
              </el-descriptions-item>
              <!-- <el-descriptions-item label="详细地址">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  详细地址
                </template>
                {{ item.detailAddress }}
              </el-descriptions-item> -->
              <el-descriptions-item label="证件有效期">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  证件有效期
                </template>
                {{ item.cardStart }} -
                {{ !item.cardEnd && item.cardStart ? "长期" : item.cardEnd }}
              </el-descriptions-item>
              <el-descriptions-item label="联系人名称">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  联系人名称
                </template>
                {{ item.contactName }}
              </el-descriptions-item>
              <el-descriptions-item label="联系电话">
                <template slot="label">
                  <span
                    style="color: rgba(201, 66, 66, 1)"
                    v-if="$route.meta.policyType != 2"
                    >*</span
                  >
                  联系电话
                </template>
                {{ item.contactPhone }}
              </el-descriptions-item>
              <el-descriptions-item
                label="联系邮箱"
                v-if="!$route.meta.policyType == 1"
                >{{ item.contactEmail }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="承保公司"
        name="4"
        v-if="permissions.acceptCompanyList"
      >
        <div style="padding: 18px 12px 12px">
          <div
            v-for="(item, index) in detailData.acceptCompanyList"
            :key="index"
          >
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="保险公司">
                <template slot="label">
                  <span style="color: rgba(201, 66, 66, 1)">*</span>
                  保险公司
                </template>
                {{ item.companyName }}
              </el-descriptions-item>
              <el-descriptions-item label="分支机构">
                <template slot="label"
                  ><span style="color: rgba(201, 66, 66, 1)">*</span
                  >分支机构</template
                >
                {{ item.branch }}
              </el-descriptions-item>
              <el-descriptions-item label="承保比例">
                <template slot="label">承保比例</template>
                {{ item.acceptRate }}
              </el-descriptions-item>
            </el-descriptions>
            <div class="el-radio" style="display: block; margin-bottom: 18px">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': item.acceptPrimary,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">主承保公司</span>
            </div>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="标的信息"
        name="5"
        v-if="permissions.policySubjectList && $route.meta.policyType !== 3"
      >
        <div style="padding: 18px 12px 12px">
          <div
            style="display: block; margin-bottom: 18px"
            v-if="detailData.policySubjectList"
          >
            <div class="el-radio">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': detailData.isSingle,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">单一标的</span>
            </div>
            <div class="el-radio">
              <span
                :class="{
                  'el-radio__input': true,
                  'is-checked': !detailData.isSingle,
                }"
              >
                <span class="el-radio__inner"></span>
              </span>
              <span class="el-radio__label">多个标的</span>
            </div>
          </div>
          <div v-if="detailData.policySubjectList && detailData.isSingle">
            <div
              v-for="(item, index) in detailData.policySubjectList"
              class="list-item"
              :key="index"
            >
              <el-descriptions
                class="margin-top"
                labelClassName="policy-label-style"
                contentClassName="policy-label-style"
              >
                <el-descriptions-item label="设备品牌">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备品牌
                  </template>
                  {{ item.brandName }}
                </el-descriptions-item>
                <el-descriptions-item label="设备类型">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备类型
                  </template>
                  {{ item.deviceTypeName }}
                </el-descriptions-item>
                <el-descriptions-item label="设备型号">
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    设备型号
                  </template>
                  {{ item.equipmentType }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    车架号 (机身编号)
                  </template>
                  {{ item.frameNo }}
                </el-descriptions-item>
                <el-descriptions-item v-if="$route.meta.policyType == 1">
                  <template slot="label"> 是否上牌设备 </template>
                  <el-switch disabled v-model="item.vehicleAccredit">
                  </el-switch>
                </el-descriptions-item>
                <el-descriptions-item v-if="$route.meta.policyType !== 3">
                  <template slot="label"> 车牌号 </template>
                  {{ item.plate }}
                </el-descriptions-item>
                <!-- <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)" v-if="$route.meta.policyType === 1">*</span>
                    发动机编号
                  </template>
                  {{ item.engineNo }}
                </el-descriptions-item>
                <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">生产编号</template>
                  {{ item.itemNo }}
                </el-descriptions-item> -->
                <el-descriptions-item>
                  <template slot="label">
                    <span
                      style="color: rgba(201, 66, 66, 1)"
                      v-if="$route.meta.policyType === 1"
                      >*</span
                    >
                    新设备购置价
                  </template>
                  {{ item.purchasePrice }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    出厂日期
                  </template>
                  {{ item.productionDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span style="color: rgba(201, 66, 66, 1)">*</span>
                    主险保额
                  </template>
                  {{ item.mainInsuranceAmount }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <span
                      style="color: rgba(201, 66, 66, 1)"
                      v-if="$route.meta.policyType === 1"
                      >*</span
                    >
                    {{ $route.meta.policyType === 2 ? "吨位(Kg)" : "整备质量" }}
                  </template>
                  {{ item.curbWeight }}
                </el-descriptions-item>
                <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">年折旧率</template>
                  {{ item.yearlyDepreciation }}%
                </el-descriptions-item>
                <!-- <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">经销商</template>
                  {{ item.agency }}
                </el-descriptions-item> -->
                <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">产地</template>
                  <div>
                    <div class="el-radio">
                      <span
                        :class="{
                          'el-radio__input': true,
                          'is-checked': item.origin == 1,
                        }"
                      >
                        <span class="el-radio__inner"></span>
                      </span>
                      <span class="el-radio__label">国产</span>
                    </div>
                    <div class="el-radio">
                      <span
                        :class="{
                          'el-radio__input': true,
                          'is-checked': item.origin != 1,
                        }"
                      >
                        <span class="el-radio__inner"></span>
                      </span>
                      <span class="el-radio__label">进口</span>
                    </div>
                  </div>
                </el-descriptions-item>
                <!-- <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">所在位置</template>
                  {{ item.address }}
                </el-descriptions-item>
                <el-descriptions-item v-if="$route.meta.policyType === 1">
                  <template slot="label">累计工作小时</template>
                  {{ item.workTime }}
                </el-descriptions-item> -->
              </el-descriptions>
            </div>
          </div>
          <div v-else>
            <el-table :data="detailData.policySubjectList" border>
              <el-table-column
                label="序号"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column
                label="设备品牌"
                prop="brandName"
                width="180"
              ></el-table-column>
              <el-table-column
                label="设备类型"
                prop="deviceTypeName"
                width="180"
              ></el-table-column>
              <el-table-column
                label="设备型号"
                prop="equipmentType"
                width="180"
              ></el-table-column>
              <el-table-column
                label="车架号 (机身编号) "
                prop="frameNo"
              ></el-table-column>
              <el-table-column
                label="车牌号"
                prop="plate"
                v-if="$route.meta.policyType !== 3"
              ></el-table-column>
              <!-- <el-table-column label="发动机编号"
                prop="engineNo"></el-table-column>
              <el-table-column label="生产编号"
                prop="itemNo"></el-table-column> -->
              <el-table-column
                label="新设备购置价"
                prop="purchasePrice"
              ></el-table-column>
              <el-table-column
                label="出厂日期"
                prop="productionDate"
              ></el-table-column>
              <el-table-column
                label="主险保额"
                prop="mainInsuranceAmount"
              ></el-table-column>
              <el-table-column
                :label="$route.meta.policyType === 2 ? '吨位(Kg)' : '整备质量'"
                prop="curbWeight"
              ></el-table-column>
              <el-table-column
                label="年折旧率"
                prop="yearlyDepreciation"
              ></el-table-column>
              <!-- <el-table-column label="经销商"
                prop="agency"></el-table-column> -->
              <el-table-column label="产地" prop="origin">
                <template slot-scope="{ row }">
                  {{ row.origin == 1 ? "国产" : "进口" }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="所在位置"
                prop="address"></el-table-column>
              <el-table-column label="累计工作小时"
                prop="workTime"></el-table-column> -->
            </el-table>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        title="保障计划"
        name="6"
        v-if="$route.meta.policyType == 1"
      >
        <div style="padding: 18px 12px 12px">
          <div style="margin-bottom: 16px">
            承保方案：{{ detailData.schemeName }}
          </div>
          <el-table
            :data="detailData.policyPlanList"
            border
            v-if="permissions.policyPlanList"
            style="width: 1000px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column prop="termConfigurationName">
              <template slot="header">
                <div>险种（条款）名称</div>
              </template>
            </el-table-column>
            <el-table-column prop="sumInsured" label="保额/限额">
              <template slot="header">
                <div>保额/限额</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="limitCompensation"
              label="每次赔偿限额"
            ></el-table-column>
            <el-table-column prop="rate" label="年费率">
              <template slot-scope="scope"
                >{{ scope.row.rate
                }}{{ scope.row.rateType ? "%" : "元" }}</template
              >
            </el-table-column>
            <el-table-column prop="premium" label="保费">
              <template slot="header">
                <!-- <span class="nmmdx">*</span> -->
                <div>保费(元)</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="insurance-company" v-if="permissions.policyPlanList">
            <div>
              总保费：{{
                detailData.totalPremium
                  ? Number(detailData.totalPremium).toFixed(2)
                  : "0.00"
              }}元
            </div>
          </div>
          <div class="clause-title" v-if="permissions.extensionClause">
            免费扩展条款
          </div>
          <el-input
            type="textarea"
            v-if="permissions.extensionClause"
            v-model="detailData.extensionClause"
            :rows="6"
            disabled
          ></el-input>
          <div class="clause-title" v-if="permissions.specialAgreement">
            特别约定
          </div>
          <el-input
            type="textarea"
            v-if="permissions.specialAgreement"
            v-model="detailData.specialAgreement"
            :rows="6"
            disabled
          ></el-input>
          <div class="clause-title" v-if="permissions.specialVersion">
            特别说明
          </div>
          <el-input
            type="textarea"
            v-if="permissions.specialVersion"
            v-model="detailData.specialVersion"
            :rows="6"
            disabled
          ></el-input>
          <div class="clause-title">每次事故绝对免赔额</div>
          <div v-if="permissions.absoluteDeductible">
            <div class="policy-label-style">
              <el-checkbox
                disabled
                v-model="detailData.absoluteDeductibleCheckbox"
                >扩展不计免赔1（0免赔）</el-checkbox
              >
            </div>
            <el-input
              type="textarea"
              style="margin-bottom: 12px"
              v-model="detailData.absoluteDeductible"
              :rows="6"
              disabled
            ></el-input>
          </div>
          <div v-if="permissions.absoluteDeductible2">
            <div class="policy-label-style">
              <el-checkbox
                disabled
                v-model="detailData.absoluteDeductibleCheckbox2"
                >扩展不计免赔2（绝对免赔1000元）</el-checkbox
              >
            </div>
            <el-input
              type="textarea"
              style="margin-bottom: 12px"
              v-model="detailData.absoluteDeductible2"
              :rows="6"
              disabled
            ></el-input>
          </div>
          <div v-if="permissions.absoluteDeductible3">
            <div class="policy-label-style">
              <el-checkbox
                disabled
                v-model="detailData.absoluteDeductibleCheckbox3"
                >未扩展不计免赔</el-checkbox
              >
            </div>
            <el-input
              type="textarea"
              v-model="detailData.absoluteDeductible3"
              :rows="6"
              disabled
            ></el-input>
          </div>
          <!-- <div v-if="(permissions.ep)">
            <div class="policy-label-style">电子保单</div>
            <div v-if="(detailData.ep && detailData.ep.fileType)"
              class="display: flex;align-items: center;">
              <img src="@/assets/images/policy/png.png"
                class="policy-ep-img"
                alt
                v-if="detailData.ep.fileType == 'png'"
                srcset />
              <img src="@/assets/images/policy/jpg.png"
                class="policy-ep-img"
                v-else-if="detailData.ep.fileType == 'jpg'"
                alt
                srcset />
              <img src="@/assets/images/policy/jpeg.png"
                class="policy-ep-img"
                v-else-if="detailData.ep.fileType == 'jpeg'"
                alt
                srcset />
              <img src="@/assets/images/policy/pdf.png"
                class="policy-ep-img"
                v-else-if="detailData.ep.fileType == 'pdf'"
                alt
                srcset />
              <span style="color: #333333;">{{ detailData.ep.fileName }}</span>
              <span class="ep-b"
                @click="onPreview">预览</span>
              <span class="ep-b"
                @click="onDownload">下载</span>
            </div>
            <div v-else>无</div>
          </div>-->
        </div>
      </AssociationScrollView>
      <AssociationScrollView
        v-if="detailData.surePress && detailData.verifyLine"
        title="确认投保"
        name="7"
      >
        <div class="ins-confir">
          <!-- 身份认证信息 -->
          <div class="ins-confir-item">
            <div class="ins-confir-item-title">身份认证信息</div>
            <div class="ins-confir-item-data">
              <el-image
                class="ins-confir-item-data-avatar"
                :src="confirData.photo"
                fit="fit"
              ></el-image>
              <div class="ins-confir-item-data-info">
                <div>姓名：{{ confirData.policyHolder || "---" }}</div>
                <div>
                  身份证号：{{ confirData.confirmationUserCardNo || "---" }}
                </div>
                <div>认证时间：{{ confirData.authTime || "---" }}</div>
              </div>
            </div>
          </div>
          <!-- 电子签名 -->
          <div class="ins-confir-item">
            <div class="ins-confir-item-title">电子签名</div>
            <div class="ins-confir-item-data">
              <div class="ins-confir-item-data-info">
                <div>
                  <div>投保人签名：</div>
                  <!-- 签名 -->
                  <el-image
                    class="ins-confir-item-data-info-sign"
                    :src="confirData.signUrl"
                    fit="contain"
                  ></el-image>
                </div>

                <div>签名时间：{{ confirData.signTime || "---" }}</div>
              </div>
            </div>
          </div>
        </div>
      </AssociationScrollView>
      <!-- <AssociationScrollView title="保单文件"
        name="7"
        v-if="$route.meta.policyType === 3">
        <FormListUpload required
          title='电子保单'
          attachmentType='dzbd'
          :isEdit="false"
          v-model="detailData.attachments.dzbd"></FormListUpload>
        <FormListUpload required
          title='营业执照'
          attachmentType='yyzz'
          :isEdit="false"
          v-model="detailData.attachments.yyzz"></FormListUpload>
        <FormListUpload
          title='开票信息'
          attachmentType='kpxx'
          :isEdit="false"
          v-model="detailData.attachments.kpxx"></FormListUpload>
        <FormListUpload
          title='其他信息'
          attachmentType='qtxx'
          :isEdit="false"
          v-model="detailData.attachments.qtxx"></FormListUpload>
      </AssociationScrollView> -->
      <AssociationScrollView title="保单文件" name="8">
        <div v-if="permissions.ep">
          <div class="policy-label-style">电子保单</div>
          <div
            v-if="detailData.ep && detailData.ep.fileType"
            class="display: flex;align-items: center;"
          >
            <img
              src="@/assets/images/policy/png.png"
              class="policy-ep-img"
              alt
              v-if="detailData.ep.fileType == 'png'"
              srcset
            />
            <img
              src="@/assets/images/policy/jpg.png"
              class="policy-ep-img"
              v-else-if="detailData.ep.fileType == 'jpg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/jpeg.png"
              class="policy-ep-img"
              v-else-if="detailData.ep.fileType == 'jpeg'"
              alt
              srcset
            />
            <img
              src="@/assets/images/policy/pdf.png"
              class="policy-ep-img"
              v-else-if="detailData.ep.fileType == 'pdf'"
              alt
              srcset
            />
            <span style="color: #333333">{{ detailData.ep.fileName }}</span>
            <span class="ep-b" @click="onPreview">预览</span>
            <span class="ep-b" @click="onDownload">下载</span>
          </div>
          <div v-else>无</div>
        </div>
        <FormListUpload
          required
          title="营业执照"
          attachmentType="yyzz"
          :limitSize="10"
          :isEdit="false"
          v-model="detailData.attachments.yyzz"
        ></FormListUpload>
        <FormListUpload
          title="开票信息"
          attachmentType="kpxx"
          :limitSize="10"
          :isEdit="false"
          v-model="detailData.attachments.kpxx"
        ></FormListUpload>
        <FormListUpload
          title="其他信息"
          attachmentType="qtxx"
          :limitSize="10"
          :isEdit="false"
          v-model="detailData.attachments.qtxx"
        ></FormListUpload>
      </AssociationScrollView>
      <AssociationScrollView title="审核流程" name="9">
        <policyAuditProcess :auditList="auditProcessList" />
      </AssociationScrollView>
    </AssociationScroll>
    <div class>
      <el-image
        style="width: 0px; height: 0px"
        ref="img-box"
        :preview-src-list="srcList"
      ></el-image>
    </div>
    <repetitionHistory
      v-model="repetitionHistoryVisible"
      :formData="repetitionHistoryForm"
    />
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import repetitionHistory from "@/views/policyManagement/components/repetitionHistory";
import policyAuditProcess from "@/views/policyManagement/policyAudit/components/policyAuditProcess.vue";

export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    confirData: {
      type: Object,
      default: () => {},
    },
    orderType: {
      type: [String, Number],
      default: 1, //  类型，1：询价单、2：保单、3：批单
    },
    auditProcessList: {
      type: Array,
      default: () => [], //  审核流程
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isUpdateRecord: false,
      updateNew: [],
      updateOld: [],
      updateType: "附件",
      srcList: [],
      repetitionHistoryForm: {
        cardNo: "",
        salesmanId: "",
        dataType: "",
        orderType: "",
      },
      repetitionHistoryVisible: false,
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    FormListUpload,
    repetitionHistory,
    policyAuditProcess,
  },
  methods: {
    handleClickAnchor(v) {
      this.$refs["anchor" + v.index].scrollIntoView();
    },
    handleCopy(v) {
      this.$copyText(v).then(
        (e) => {
          console.log("copy arguments e:", e);
          this.$message.success("复制成功!");
        },
        (e) => {
          console.log("copy arguments e:", e);
          this.$message.error("复制失败!");
        }
      );
    },
    onPreview() {
      // this.detailData
      if (this.detailData.ep.fileType == "pdf") {
        window.open(this.detailData.ep.url, "_blank");
      } else {
        this.srcList = [this.detailData.ep.url];
        this.$refs["img-box"].showViewer = true;
      }
    },
    onDownload() {
      if (this.detailData.ep.fileType == "pdf") {
        this.pdfDownlad(this.detailData.ep.url, this.detailData.ep.fileName);
      } else {
        this.downloadImage(this.detailData.ep.url, this.detailData.ep.fileName);
      }
    },
    downloadImage(url, downloadName) {
      const link = document.createElement("a");
      link.setAttribute("download", downloadName);
      const image = new Image();
      // 添加时间戳，防止浏览器缓存图片
      image.src = url + "?timestamp=" + new Date().getTime();
      // 设置 crossOrigin 属性，解决图片跨域报错
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = () => {
        link.href = this.getBase64Image(image);
        link.click();
      };
    },
    pdfDownlad(url, fileName) {
      let xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      let that = this;
      xhr.onload = function () {
        if (this.status == 200) {
          //接受二进制文件流
          var blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
      // return dataURL.replace("data:image/png;base64,", "");
    },
    handleShowUpdate(e) {
      if (
        e.fieldDesc != "项目备注" &&
        e.fieldDesc != "未扩展不计免赔" &&
        e.fieldDesc != "扩展不计免赔2" &&
        e.fieldDesc != "扩展不计免赔1" &&
        e.fieldDesc != "特别约定" &&
        e.fieldDesc != "免费扩展条款"
      ) {
        this.updateNew = e.updateNew ? JSON.parse(e.updateNew) : "";
        this.updateOld = e.updateOld ? JSON.parse(e.updateOld) : "";
      } else {
        this.updateNew = e.updateNew;
        this.updateOld = e.updateOld;
      }
      this.updateType = e.fieldDesc;
      this.isUpdateRecord = true;
    },
    lookRepetition(v, dataType) {
      this.repetitionHistoryForm = {
        cardNo: v.cardNo,
        salesmanId: this.detailData.salesmanId,
        dataType,
        orderType: 1,
        upstreamAgentId: v.upstreamAgentId,
      };
      this.repetitionHistoryVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.personnel_repetition {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  padding: 8px 12px;
  margin-bottom: 24px;
  background: #cce6ff;
  border-radius: 8px;
  &_details {
    color: #0080ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .clause-title {
    margin: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  .steps-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .decoration {
      height: 20px;
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
      margin-right: 10px;
      margin-left: 14px;
    }
    .decoration2 {
      flex: 1;
      height: 1px;
      margin-left: 8px;
      margin-right: 20px;
      background-color: #cccccc;
    }
  }
  .steps-box {
    height: calc(100% - 50px);
    padding-top: 24px;
    overflow: auto;
    .step {
      display: flex;
      &-left {
        position: relative;
        width: 6px;
        background-color: #e1e1e1;
        margin-right: 10px;
        margin-left: 14px;
        img {
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
        }
      }
      &-left2 {
        background-color: transparent;
      }
      &-right {
        flex: 1;
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #333333;
          margin-bottom: 4px;
        }
        .step-body {
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}
.list-item {
  border-top: 1px dashed #999;
  padding-top: 15px;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
}
</style>
<style lang="scss">
.policy-label-style {
  align-items: center !important;
  font-size: 14px;
  color: #333333;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .ep-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .policy-ep-img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}
.ins-confir {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  &-item {
    flex: 1;
    background: #f6f8f9;
    border-radius: 4px 4px 4px 4px;
    //max-width: 700px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    &-data {
      display: flex;
      &-avatar {
        width: 108px;
        height: 108px;
        border-radius: 50%;
      }
      &-info {
        display: column;
        align-content: space-around;
        &-sign {
          height: 53px;
          width: fit-content;
        }
        > div {
          margin-top: 14px;
          display: flex;
        }
      }
    }
  }
}
</style>
